import React, { useState } from 'react';
import {
  Button,
  TextField,
  Box,
  Typography,
  CircularProgress,
  List,
  ListItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import nodeAxiosFirebase from '../../../utils/nodeAxiosFirebase';

const DocData = ({ businessId }) => {
  const { t } = useTranslation();
  const [docPath, setDocPath] = useState('');
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleChangeValue = (e) => {
    setDocPath(e.target.value);
  };

  const handleGetData = async () => {
    setLoading(true);
    setError(null);
    try {
      const nodeData = await nodeAxiosFirebase({
        t,
        method: 'POST',
        url: `business/admin`,
        errorToast: t('errorLoadingDocument'),
        body: {
          type: 'doc',
          path: docPath,
          businessId,
        },
      });

      setData(nodeData);
    } catch (error) {
      console.error('Error getting node data:', error);
      setError(t('errorLoadingDocument'));
    } finally {
      setLoading(false);
    }
  };

  const renderData = (obj, level = 0) => {
    if (typeof obj !== 'object' || obj === null) {
      return (
        <Typography variant="body2" sx={{ pl: level * 2 }}>
          {obj}
        </Typography>
      );
    }

    if (Array.isArray(obj)) {
      return (
        <List sx={{ pl: level * 2 }}>
          {obj.map((item, index) => (
            <ListItem key={index} sx={{ display: 'block' }}>
              {renderData(item, level + 1)}
            </ListItem>
          ))}
        </List>
      );
    }

    // Sort the keys alphabetically before rendering
    const sortedKeys = Object.keys(obj).sort();

    return (
      <List>
        {sortedKeys?.map((key) => (
          <ListItem key={key} sx={{ display: 'block', pl: level * 2 }}>
            {key === 'date' ||
            key === 'targetDate' ||
            key === 'invoiceDate' ||
            key === 'timeStamp' ||
            key === 'lastUpdate' ||
            key === 'lastRead' ? (
              <>
                <Typography variant="body1" color="black">
                  {key}: {formatValue(obj[key], 'date')}
                </Typography>
              </>
            ) : typeof obj[key] === 'object' && obj[key] !== null ? (
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="body1">{key}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {renderData(obj[key], level + 1)}
                </AccordionDetails>
              </Accordion>
            ) : (
              <>
                <Typography variant="body1" color="black">
                  {key}: {formatValue(obj[key])}
                </Typography>
              </>
            )}
          </ListItem>
        ))}
      </List>
    );
  };

  const formatValue = (value, type) => {
    if (type === 'date') {
      const date = moment.unix(value?._seconds);

      return date.format('YYYY-MM-DD HH:mm');
    }
    return value;
  };

  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ mb: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
        <TextField
          label={t('documentPath')}
          value={docPath}
          onChange={handleChangeValue}
          fullWidth
        />
        <Button
          variant="contained"
          onClick={handleGetData}
          disabled={!docPath || loading}
        >
          {loading ? <CircularProgress size={24} /> : t('getData')}
        </Button>
      </Box>
      <Box sx={{ mt: 2 }}>
        {error && (
          <Typography color="error" sx={{ mb: 2 }}>
            {error}
          </Typography>
        )}
        {!loading && data && (
          <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t('dataPreview')}
            </Typography>
            {renderData(data?.data)}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DocData;
