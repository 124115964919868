import { Routes, Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

//general
import PrivateRoute from './components/PrivateRoute';
import Reports from './screens/specific/Reports';

const InsightsRoutes = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <PrivateRoute
            permission={['ADMIN', 'MANAGER', 'STANDARD', 'OWNER', 'PERSONAL']}
          >
            <Reports />
          </PrivateRoute>
        }
      />
    </Routes>
  );
};

export default InsightsRoutes;
